export const SYNTAX_ERROR = 'SYNTAX_ERROR'
export const VALIDATION_ERROR = 'VALIDATION_ERROR'

export const BRAND_NOT_FOUND_ERROR = 'BRAND_NOT_FOUND_ERROR'
export const MODEL_NOT_FOUND_ERROR = 'MODEL_NOT_FOUND_ERROR'
export const INVALID_BRAND_MODEL_ERROR = 'INVALID_BRAND_MODEL_ERROR'
export const ZIP_CODE_NOT_FOUND_ERROR = 'ZIP_CODE_NOT_FOUND_ERROR'
export const ZIP_CODE_CITY_PAIR_ERROR = 'ZIP_CODE_CITY_PAIR_ERROR'
export const CONTRACT_EXPIRED_ERROR = 'CONTRACT_EXPIRED_ERROR'
export const CONTRACT_ALREADY_ACCEPTED_ERROR = 'CONTRACT_ALREADY_ACCEPTED_ERROR'
export const DRAFT_DOCUMENTS_UNAVAILABLE_ERROR = 'DRAFT_DOCUMENTS_UNAVAILABLE_ERROR'
export const EXTERNAL_SERVICE_ERROR = 'EXTERNAL_SERVICE_ERROR'
export const EMAIL_ATTEMPT_ERROR = 'EMAIL_ATTEMPT_ERROR'
export const FLEET_INSURANCE_ERROR = 'FLEET_INSURANCE_ERROR'

export const NOT_FOUND_ERROR = 'NOT_FOUND_ERROR'
export const AUTHORIZATION_ERROR = 'AUTHORIZATION_ERROR'
export const CLIENT_DATA_ERROR = 'CLIENT_DATA_ERROR'
export const VEHICLE_TYPE_ERROR = 'VEHICLE_TYPE_ERROR'
